@import "../../../styles/common.module.scss";

.container {
  height: 100vh;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  padding: 24px;
  .card {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $complement-color-light;
    color: $complement-color-dark;
    border-radius: 12px;
    align-items: center;
    font-size: clamp(20px, 4vw, 44px);
    font-weight: 400;
    padding: 12px 16px;

    &:hover {
      cursor: pointer;
    }
    a {
      list-style: none;
      text-decoration: none;
      color: $complement-color-dark;
    }
  }
}

/* Small screens */
@media screen and (max-width: 767px) {
  .container {
    .card {
      font-size: 20px;
      height: 4rem;

      &:hover {
      }
    }
  }
}

/* Medium screens */
@media screen and (min-width: 768px) and (max-width: 1279px) {
  .container {
    .card {
      font-size: 24px;
      height: 4rem;

      &:hover {
        border: 0.4px solid;
      }
    }
  }
}

/* Large screens */
@media screen and (min-width: 1280px) {
  .container {
    .card {
      font-size: 22px;
      height: 4rem;

      &:hover {
      }
    }
  }
}
