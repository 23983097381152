@import "../../../styles/variables";

.container {
  padding: 24px;

  .title {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;

    p {
      font-size: 32px;
      font-weight: 700;
      color: $complement-color-dark;
    }

    select {
      border: none;
      outline: none;
      font-size: 20px;
      font-weight: 700;
      text-align: center;
      background-color: transparent;
      border-bottom: 1px solid;
    }
  }

  .dropdownSection {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
    margin: 1rem 0;

    .process {
      padding: 12px 16px;
      outline: none;
      border: none;
      border-bottom: 1px solid;
      font-weight: 700;
      background-color: transparent;
      font-size: 20px;
    }

    .dateSection {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 12px;

      label {
        font-weight: 700;
      }

      select {
        padding: 12px 16px;
        border: none;
        outline: none;
        background-color: $complement-color-light;
        font-size: 20px;
        text-align: center;
        font-weight: 700;
        border-bottom: 1px solid;
      }

      input {
        padding: 12px 16px;
        border: none;
        outline: none;
      }
    }
  }

  .inputSection {
    display: flex;
    flex-direction: row;
    gap: 12px;
    width: 80%;
    padding: 12px 0;
    margin: 1rem 0;

    input {
      border: none;
      outline: none;
      padding: 12px 16px;
      font-weight: 700;
      font-size: 20px;
    }
  }

  .report {
    margin-top: 1rem;
    height: 70%;
    overflow-y: scroll;

    table {
      width: 100%;
      border-collapse: collapse;
      border: 1px solid;

      thead {
        font-size: 16px;
        background-color: $complement-color-dark;
        color: $complement-color-light;
        font-weight: 700;
        position: sticky;
        top: 1px;
        th {
          padding: 12px 16px;
          white-space: nowrap;
        }
      }

      tbody {
        td {
          border: 1px solid;
          font-size: 16px;
          font-weight: 600;
          padding: 12px 16px;
          text-align: center;
          white-space: nowrap;
        }
      }
    }
  }
}
