.container {
  display: flex;
  height: 100vh;
  width: 100%;
  background-color: #f1f0f8;
  flex-direction: column;

  .headerSection {
    height: 80px;
    width: 100%;
    background-color: rgb(240, 240, 253);
    padding: 12px;

    .titleSection {
      display: flex;
      flex-direction: column;
      gap: 2px;
    }

    .title {
      font-size: 29px;
      font-weight: bold;
      font-family: "Urbanist";
    }
    .subtitle {
      font-size: 14px;
    }
  }

  .bodySection {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    width: 100%;
    background-color: #f1f0f8;

    .card {
      display: flex;
      flex-direction: column;
      margin: 0 auto;
      width: 50%;
      background-color: #dcdae6;
      border-radius: 12px;
      padding: 24px;
      border: 1px solid;

      .title {
        font-size: 20px;
        font-weight: bold;
        color: rgb(45, 45, 55);
      }

      .subtitle {
        font-size: 12px;
        color: rgba(45, 45, 55, 0.528);
      }

      .mainCategory {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        gap: 12px;
        margin-top: 24px;
      }

      .subline {
        height: 32px;
        width: 1px;
        margin-left: 0.5rem;
        background-color: grey;
        margin-left: 2.7rem;
      }

      .subCategory {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        gap: 12px;
        margin-left: 2rem;
      }

      /* Increase the size of the checkbox */
      input[type="checkbox"] {
        -webkit-transform: scale(1.4); /* Safari and Chrome */
        -moz-transform: scale(1.4); /* Firefox */
        -ms-transform: scale(1.4); /* IE 9 */
        -o-transform: scale(1.4); /* Opera */
        transform: scale(1.4);

        &:hover {
          cursor: pointer;
          border: 1px solid green;
        }
      }

      .columnSection {
        display: flex;
        flex-direction: column;
        gap: 2px;
      }

      input {
        width: 24px;
      }
    }
  }

  .buttonSection {
    display: flex;
    flex-direction: row;
    margin: 1rem 2rem;
    gap: 12px;
    width: 100%;
  }

  button {
    padding: 8px 12px;
    background-color: none;
    border: none;
    border-radius: 4px;
    font-weight: 700;
    transition: 0.3s all;

    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }
  }

  .primary {
    background: linear-gradient(25deg, red, orange);
    color: white;
  }

  .secondary {
    border: 1px solid grey;
    font-weight: 400;
  }
}
