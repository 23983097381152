//Common FIles :
@import '../../../styles/variables';

.link{
    text-decoration: none;
    color: $complement-color-light;
    padding: 4px 12px;
    background-color: $complement-color-dark;
    border-radius: 4px;
    font-size: 12px;


    &:hover{
        background-color: lighten($complement-color-dark ,10%);
    }
}

.time{
    text-decoration: none;
    color: $complement-color-dark;
    padding: 4px;
    background-color: darken($complement-color-light,5%);
    border-radius: 4px;
    font-size: 10px;
    white-space: nowrap;
}


.main_title{
    display: flex;
    justify-content: left;
    padding: 12px;
    font-size: 24px;
    font-family: "Urbanist", sans-serif;
    font-weight: 700;
}

.clientinfo{
  font-weight: 700;
  font-size: 1.5vw;
  background: #FF6840;
  padding: 12px;
  border-radius: .25rem;
  text-align: center;
  font-family: 'urbanist',sans-serif;
  color: white;
}




.select {
    border: none;
    outline: none;
    border-bottom: 1px solid;
    background-color:darken($complement-color-light,20);
    padding: 4px;
    font-size: 16px;
}

.container {
    font-size: 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 24px;

    .left_section_hidden{
        display: none;
    }

    .left_section {
        display: flex;
        flex-direction: column;
        gap: 20px;
        transition: width 0.5s ease;
        width: 100%;
        .top_section {
            display: flex;
            flex-direction: row;
            gap: 24px;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;

            .loadingicon{
                font-size: 24px;
                border-radius: 60%;
                padding: 12px;
                color: $complement-color-dark;
                animation: rotate 6s infinite linear;
            }

            .loadingiconClicked{
                font-size: 24px;
                border-radius: 60%;
                padding: 12px;
                animation: rotate .4s infinite linear;
                backdrop-filter: blur(4px);
            }

            @keyframes rotate {
                from {
                    transform: rotate(0deg);
                }
                to {
                    transform: rotate(360deg);
                }
            }



        }

        .line{
            height: 1px;
            background-color: $complement-color-light;
        }

        .bottom_section {
            .table_section {
                table {
                    overflow: scroll;
                    color: black;
                    background: white;
                    border-collapse: collapse;
                    width: 100%;

                    th,td{
                        text-align: center;
                        padding: 16px 12px;
                    }

                    th{
                        background-color: $complement-color-dark;
                        color: $complement-color-light;
                        font-weight: 500;
                        font-size: 16px;
                        padding: 16px 12px;
                        text-transform: uppercase;
                    }

                    td{
                        font-weight: 500;
                        text-transform: capitalize;
                    }

                    tr{
                        padding: 12px 0;
                        background-color: $complement-color-light;
                        border: .5px solid $complement-color-dark;
                        &:hover{
                            cursor: pointer;
                            background-color: darken($complement-color-light,10%);


                        }
                    }

                    .button {
                        border-radius: 4px;
                        background: linear-gradient(30deg, orange, red);
                        color: rgb(237, 237, 237);
                        padding: 4px 12px;
                        font-size: 16px;
                        border: none;
                        cursor: pointer;

                        &:hover {
                            background: linear-gradient(30deg, red, orange);
                            color: white;
                        }
                    }
                }
            }
        }
    }
    .right_section {
        display: flex;
        flex-direction: row;
        gap: 4rem;
        .iqa_form {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 60vh;
            width: 512px;
            background-color: $complement-color-light;
            color: $complement-color-dark;
            border: 1px solid $complement-color-dark;
            padding: 40px;
            border-radius: 4px;
            border: .5px solid;
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;


            .icon_section {
                display: flex;
                justify-content: space-between;
                font-size: 18px;
                font-weight: 700;

                .icon{
                    font-size: 18px;
                    cursor: pointer;
                }


            }

            .label_section {
                padding: 10px 36px;
                background: darken($complement-color-light,10%);
                color: $complement-color-dark;
                border-radius: 6px;
                cursor: pointer;
            }

            input {
                padding: 10px 36px;
                background-color: lighten($complement-color-light,20%);
                border-radius: 6px;
                outline: none;
                border: none;

            }

            .button_section {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                font-weight: 700;
                font-size: 16px;


                .reject_button {
                    background: linear-gradient(30deg, #F85050, #D80A0A);
                    border-radius: 6px;
                    color: white;
                    text-align: center;
                    font-size: 16px;
                    border: none;
                    width: 120px;
                    line-height: 38px;

                    &:hover {
                        background: linear-gradient(30deg, #D80A0A, #F85050);
                        cursor: pointer;
                        color: white;
                    }
                }

                .park_button {
                    border-radius: 6px;
                    background: $complement-color-light;
                    color: $complement-color-dark;
                    text-align: center;
                    font-size: 16px;
                    border: none;
                    width: 120px;
                    line-height: 38px;

                    &:hover {
                        cursor: pointer;
                    }
                }

                .approve_button {
                    border-radius: 5px;
                    background: linear-gradient(30deg, orange, red);
                    color: white;
                    font-size: 16px;
                    border: none;
                    line-height: 38px;
                    width: 120px;
                    text-align: center;

                    &:hover {
                        cursor: pointer;
                        background: linear-gradient(30deg, red, orange);
                        color: white;
                    }
                }
            }
        }

        .supportform{
            display: flex;
            flex-direction: column;
            width: 512px;
            height: 560px;
            background-color:lighten($complement-color-light,4%);
            border-radius: 4px;
            gap: 12px;
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
            overflow-y: auto;
            border: 1px solid $complement-color-dark;

            .imagesection{
                display: flex;
                flex-direction: column;
                gap: 8px;

                img{
                    border-radius: 8px;
                    border: 1px solid;
                    object-fit: contain;
                    width: 75%;
                    margin-left: 2rem;
                }

                .feedbackimg{
                    border-radius: 8px;
                    border: 1px solid;
                    object-fit: contain;
                    width: 75%;
                    margin-left: 2rem;
                }

                .textSection{
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    padding: 24px;
                    background-color: $complement-color-dark;
                    color: $complement-color-light;
                    font-weight: 700;

                    .author{
                        font-size: 16px;

                    }

                    .timestamp{
                        font-size: 12px;
                    }

                    .rejectionType{
                        font-size: 16px;

                    }

                }

                p{
                    padding: 8px;
                    background-color: $complement-color-light;
                    color: $complement-color-dark;
                    margin: 0;

                }

                .line{
                    width: 100%;
                    height: 1px;
                    background-color: $complement-color-dark;
                }
            }
        }

        .view_icon_form {
            font-size: 14px;
            font-weight: 400;
            position: absolute;
            left: 460px;
            top: 200px;
            display: flex;
            flex-direction: column;
            padding: 24px;
            border-radius: 4px;
            gap: 16px;
            backdrop-filter: blur(12px);
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

            .view_icon_button {
                text-align: center;
                font-weight: 400;
                font-size: 12px;
                padding: 8px;
                background: $dominant-color-light;
                color: $dominant-color-dark;
                border-radius: 3px;
                border: 1px solid $dominant-color-dark;

                &:hover{
                    background: $dominant-color-dark;
                    color: $dominant-color-light;
                    cursor: pointer;
                }
            }
        }

            .approval_form {
                display: flex;
                flex-direction: column;
                width: 480px;
                background: $complement-color-light;
                padding: 24px;
                border-radius: 4px;
                gap: 24px;
                border: .5px solid;
                box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

                .icon_section {
                    display: flex;
                    cursor: pointer;

                    .icon{
                        font-size: 20px;
                        padding: 8px;
                        border-radius: 60%;
                        background-color:$complement-color-dark;
                        color: $complement-color-light;

                        &:hover{
                            opacity: 60%;
                        }
                    }
                }

                .label_section {
                    color: $complement-color-dark;
                    border-radius: 3px;
                    font-size: 20px;
                    font-weight: 600;
                }

                .button_section {
                    display: flex;
                    flex-direction: row;
                    font-weight: 500;
                    justify-content: space-between;

                    .cancel_button {
                        border-radius: 5px;
                        background: rgb(134, 134, 134);
                        color: white;
                        font-size: 16px;
                        border: none;
                        font-weight: 700;
                        line-height: 38px;
                        width: 120px;
                        text-align: center;

                        &:hover {
                            cursor: pointer;
                            background: rgb(100, 100, 100);
                            color: white;
                        }
                    }


                    .approve_button {
                        border-radius: 5px;
                        background: linear-gradient(30deg, orange, red);
                        color: white;
                        font-size: 16px;
                        border: none;
                        line-height: 38px;
                        font-weight: 700;
                        width: 120px;
                        text-align: center;

                        &:hover {
                            cursor: pointer;
                            background: linear-gradient(30deg, red, orange);
                            color: white;
                        }
                    }
                }
            }



            .feedback_form{
                display: flex;
                flex-direction: column;
                background: darken($complement-color-light,20%);
                color: $complement-color-dark;
                padding: 32px;
                gap: 20px;
                font-weight: 600;
                font-size: 14px;
                height: 60vh;
                overflow-y: scroll;
                border-radius: 4px;
                border: .5px solid;
                width: 100%;
                box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

                .header_section{
                    display: flex;
                    justify-content: space-between;
                    font-size: 18px;
                    position: sticky;
                    top: -34px;
                    padding: 24px;
                    background: darken($complement-color-light,20%);

                    .title{
                    }

                    .icon{
                        color: $complement-color-dark;
                        &:hover{
                            cursor: pointer;
                        }
                    }

                }
                .reference_section{
                    display: flex;
                    flex-direction: row;
                    padding: 20px;
                    background: $complement-color-light;
                    border-radius: 4px;
                    gap:50px;


                    .label_section{
                        text-align: center;
                        color: black;
                        padding: 10px;
                    }
                    .image_button{
                        padding: 10px ;
                        background: rgb(187, 187, 187);
                        font-size: 16px;


                        .select{
                            border: none;
                            outline: none;
                            border-bottom: .5px solid;
                            background-color: rgb(187, 187, 187);
                            padding: 4px;
                        }
                    }
                }

                .category_section{
                    display: flex;
                    flex-direction: column;
                    padding: 20px;
                    background: $complement-color-light;
                    border-radius: 4px;
                    gap:30px;
                    border:.5px solid;

                    .remove_button{
                        padding: 10px;
                        color: red;
                        text-decoration: underline;
                        cursor: pointer;
                    }
                    .category_section_1{
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;


                        .label_section{
                            text-align: center;
                            color: $complement-color-dark;
                            padding: 10px;
                        }
                        .image_button{
                            padding: 10px;
                            background:darken($complement-color-light,20%);

                            img{
                                width: 200px;
                            }

                            input{
                                outline: none;
                                border: none;
                                padding: 8px 20px;
                            }
                        }
                    }
                    .category_section_2{
                        display: flex;
                        flex-direction: row;
                        gap:50px;
                        .label_section{
                            text-align: center;
                            color: black;
                            padding: 10px;
                        }
                        .image_button{
                            padding: 10px 25px;
                            background: rgb(187, 187, 187);
                        }
                    }
                }
                        .more_category_section {
                            display: flex;
                            align-items: center;
                            padding: 12px 28px;
                            background: $complement-color-light;
                            color: $complement-color-dark;
                            border-radius: 4px;
                            border: .5px solid;
                            gap:8px;
                            text-align: center;
                            .icon_section {
                                display: flex;
                                align-items: center;
                                font-size: 28px;
                                color: $complement-color-dark;
                                &:hover{
                                    cursor: pointer;
                                    color: darken($complement-color-dark,20%);
                                }
                            }
                            .label_section{
                                text-align: center;
                                color: $complement-color-dark;
                                padding: 10px;
                            }
                        }
                        .button_section{
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            padding: 12px 0;
                            border-radius: 12px;
                            text-align: center;
                            border-radius: 12px;
                            text-align: center;
                            .park_button {
                                border-radius: 5px;
                                background: $complement-color-light;
                                color: rgb(41, 41, 41);
                                padding: 12px 40px;
                                font-size: 16px;
                                border: none;
                                cursor: pointer;

                                &:hover {
                                    background:darken($complement-color-light,20);
                                }
                            }
                            .reject_button{
                                border-radius: 5px;
                                background: linear-gradient(30deg, #F85050, #D80A0A);
                                color: #ffd3d3;
                                padding: 12px 40px;
                                font-size: 16px;
                                border: none;
                                cursor: pointer;

                                &:hover {
                                    background: linear-gradient(30deg, #D80A0A, #F85050);
                                    color: white;
                                }
                            }
                        }

            }

    }
}


//Small Screen View :

@media only screen and (max-width: 768px) {

    .right_section{
        display: flex;
        flex-direction: column !important;
        gap: 12px !important;
    }


}
