@import "./variables";

.navButton {
  color: $dominant-color-dark;
  padding: 8px 16px;
  font-weight: 400;
  border-radius: 16px;
  border: 1.2px solid transparent;
  &:hover {
    border-radius: 16px;
    border: 1px solid $complement-color-light;
    color: $complement-color-dark;
    cursor: pointer;
  }
}

.navButtonActive {
  padding: 4px 12px;
  background-color: $dominant-color-dark;
  color: $dominant-color-light;
  border-radius: 8px;
  font-weight: 700;
  border: 1.2px solid black;
  font-size: 14px;
  cursor: pointer;
}

.primaryBtn {
  border-radius: 5px;
  background: $acent-color;
  color: $complement-color-light;
  padding: 4px 12px;
  font-size: 8px;
  font-weight: 700;
  white-space: nowrap;
  font-size: 14px;
  border: none;

  &:hover {
    cursor: pointer;
    background: $acent-color-hover;
  }
}

.secondaryBtn {
  border-radius: 5px;
  background: $complement-color-light;
  color: $complement-color-dark;
  padding: 8px 24px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  border: 1px solid $complement-color-dark;

  &:hover {
    background: $complement-color-dark;
    color: $complement-color-light;
  }
}

.loadingBtn {
  border-radius: 5px;
  background: $complement-color-light;
  color: $complement-color-dark;
  padding: 8px 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  border: 1px solid $complement-color-dark;

  &:hover {
    background: lighten($complement-color-light, 20);
    cursor: disabled;
  }
}

@media screen and (max-width: 767px) {
  .navButton {
    color: $dominant-color-dark;
    padding: 4px 8px;
    font-weight: 400;
    border-radius: 16px;
    font-size: 12px;
    border: 1.2px solid transparent;
    white-space: nowrap;
    &:hover {
      border-radius: 16px;
      border: 1px solid $complement-color-light;
      color: $complement-color-dark;
      cursor: pointer;
    }
  }

  .navButtonActive {
    white-space: nowrap;
    padding: 8px 12px;
    font-size: 12px;
    background-color: $dominant-color-dark;
    color: $dominant-color-light;
    border-radius: 16px;
    font-weight: 500;
    border: 1.2px solid black;
    cursor: pointer;
  }

  table {
    th {
      font-size: 12px !important;
      padding: 12px 8px !important;
    }

    tbody tr {
      padding: 4px 8px !important;
      font-size: 12px;

      td {
        padding: 8px 6px;
      }
    }
  }

  .primaryBtn {
    padding: 8px 12px;
    font-size: 12px;
    font-weight: 500;
    white-space: nowrap;
    border: none;
  }
}
