@import "../../../styles/variables";

* {
  padding: 0;
  margin: 0;
}

body {
  font-family: $body-font-family;
  font-size: $font-size-medium;
  background-color: $quaternary-color;
  margin: 0 auto;
  height: 100vh;
}

h1 {
  font-family: $heading-font-family;
  font-size: 29px;
  font-weight: 700;
  color: $secondary-color;
  text-transform: capitalize;
}

.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 100vh;
  align-items: center;
  margin: 0 auto;

  .leftSection {
    width: 100%;

    img {
      width: 673px;
      height: 636px;
      border-radius: 12px;
    }
  }

  .rightSection {
    width: 100%;

    .textSection {
      display: flex;
      flex-direction: column;
      gap: 6px;
    }

    button {
      padding: 12px 18px;
      background-image: linear-gradient(25deg, orange, red);
      border: none;
      color: white;
      font-weight: 700;
      border-radius: 4px;
      margin-top: 12px;

      &:hover {
        background-image: linear-gradient(25deg, red, orange);
        cursor: pointer;
      }
    }
  }
}
