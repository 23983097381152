body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #121239;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.secondary {
  color: rgba(255, 255, 255, 0.841);
  background: transparent;
  font-size: 14px;
  border: 1px solid #694494;
  border-radius: 4px;
  padding: 4px 8px;

  &:hover {
    background-color: #754aa7;
    color: white;
    font-weight: 700;
  }
}

.secondaryDisabled {
  color: rgba(255, 255, 255, 0.841);
  background: gray;
  font-size: 14px;
  border: 1px solid #694494;
  border-radius: 4px;
  padding: 4px 8px;
  text-decoration: line-through;
  cursor: not-allowed;
}

::-webkit-scrollbar {
  width: 2px; /* For vertical scrollbar */
  height: 2px; /* For horizontal scrollbar */
  scrollbar-width: thin;
}
