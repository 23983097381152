
@import '../../../styles/variables';

p{
    margin: 0;
    padding: 0;
    color: $complement-color-dark;
    font-family: "Urbanist",sans-serif;
}

.container{
    background-color: $dominant-color-light;
    display: flex;
    justify-content: center;
    .formContainer{
        position: absolute;
        top: 10vh;
        display: flex;
        flex-direction: column;
        width: 512px;
        gap: 12px;
       

        .imageSection{
            display: flex;
            justify-content: center;
            gap: 12px;
            align-items: center;

            img{
                width: 52px;
                height: 52px;
                cursor: pointer;
            }

            p{
                font-size: 36px;
                font-weight: 700;
                
            }
        }

        .formSection{

            background-color: $complement-color-light;
            border-radius: 12px;
            display: flex;
            flex-direction: column;
            gap: 16px;
            padding: 24px;
            border: 1px solid $complement-color-dark;


            p{
                font-size: 28px;
                padding: 0;
                margin: 6px 0;
                color: lighten($complement-color-dark,5%);
            }

            .subtitle{
                margin-top: -1rem;
                font-size: 12px;
                font-weight: 400;
            }

            .tag{
                font-weight: 700;
            }

            .details{
                font-weight: 400;
                font-size:18px !important;
            }
            .input{
                display: flex;
                flex-direction: column;

                p{
                    font-size: 16px;
                }
                input{
                    background-color: darken($complement-color-light,10%);
                    padding: 18px;
                    border: none;
                    outline: none;
                    border-radius: 4px;
                    font-weight: 700;
                }
            }

            .information{
                display: flex;
                justify-content: space-between;
                align-items: center;

                .checkSection{
                    display: flex;
                    align-items: center;
                    gap: 8px;

                    p{
                        font-size: 16px;
                        text-decoration: none;
                    }
                }

                p{
                    font-size: 16px;
                    text-decoration: underline;
                    cursor: pointer;
                }
            }

            .buttonSection{
                display: flex;
                width: 100%;
                justify-content: space-between;
                
            }

        }
    }
}