.container{
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;

    .titleSection{
        padding: 10px 28px;
        background-color: #B4B4B4;
        color: white;
        font-weight: 700;
    }

    .middleSection{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 24px;

        .leftSection{
            display: flex;
            flex-direction: column;

            .buttonSection{
                display: flex;
                gap: 12px;

                button{
                    padding: 12px 16px;
                    font-weight: 700;
                    background-color: #B7B7B7;
                    color: white;
                    border: none;
            
                    &:hover{
                        cursor: pointer;
                        background-color: #606060;
                    }

                    .selected{
                        background-color: #606060;
                    }
            
                }
            }

            .tableSection{

                table{
                    border-collapse: collapse;
                    background-color: #E5E5E5;
                    border-bottom: 1px solid #000000;
                    font-size: 12px;


                    thead{
                        background-color: #D9D9D9;
                        font-weight: 700;
            
                    }
            
                    tr{
                        border-bottom: 1px solid #000000;
                    }
            
                    tr,td{
            
                        padding: 12px;
                        font-size: 14px;
            
                    }
            
                    button{
                        color: white;
                        border: none;
                        padding: 6px 12px;
                        border-radius: 4px;
                        background-image: linear-gradient(25deg,orange,red);
            
                        &:hover{
                            background-image: linear-gradient(25deg,red,orange);
                            cursor: pointer;
                        }
                    }
                }
            }
            
        
            
    
        }

        .rightSection{

            display: flex;
            flex-direction: column;
            background-color: #E1E1E1;
            gap: 12px;
            padding: 48px;
            border: 1px solid black;

            .label{
                display: flex;
                flex-direction: row;
                align-items: flex-s;
                background-color: white;
                color: grey;
                color: rgb(46, 46, 46);
                padding: 12px;
            }
        
            input{
                width: 100%;
                border: none;
                outline: none;
            }
            
        }
    }

    
}