
//Color Styles:




.container{
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: rgb(225, 225, 225);

    a{
        list-style: none;
        text-decoration: none;
        color: black;
    }
    
    
    
    p,ul{
        padding: 0;
        margin: 0;
    }


    .topsection{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 8px 12px;
        border-bottom: 1px solid rgb(180, 180, 180);

        .leftsection{
            display: flex;
            align-items: center;
            gap: 24px;

            img{
                width: 136px;
                cursor: pointer;
            }

            input{
                border: none;
                padding: 6px 12px;
                border-radius: 4px;
                outline: none;
            }

        }

        .rightsection{
            position: relative;
            display: flex;
            flex-direction: row-reverse;
            align-items: flex-end;
            align-items: center;
            gap: 24px;

            .icon{
                cursor: pointer;
            }

            .profile{
                display: flex;
                align-items: center;
                gap: 12px;
                padding: 12px;
                border-radius: 6px;
                img{
                    width: 28px;
                    border-radius: 60%;
                    border: 1px solid green;
                }

                .details{
                    display: flex;
                    flex-direction: column;
                    .name{
                        font-size: 14px;
                        font-weight: 700;

                    }
                    .title{
                        font-weight: 200;
                        font-size: 12px;

                    }
                }

                &:hover{
                    cursor: pointer;
                    background-color: #f3f3f3;
                    
                }
            }

        }

        .breakMenu{
            position: absolute;
            top: 100%;
            left: 30%;
            background-color: rgb(225, 225, 225);
            border-radius: 0 0 8px 8px;
            display: none; 

            li{
                font-size: 12px;
                padding: 12px 24px;
                border-bottom: 1px solid rgb(199, 199, 199);
                list-style-type: none;
                width: auto;

                &:hover{
                    cursor: pointer;
                    background-color: #f3f3f3;
                }
                
            }
        }

        .profile:hover +  .breakMenu {
            display: flex; /* show mega menu on profile hover */
            flex-wrap: wrap;
        }

        .breakMenu:hover + .breakMenu{
            display: flex;
            flex-wrap: wrap;
        }

        

    }

    .bottomsection{
        display: flex;
        gap: 24px;
        padding: 6px 12px;



        .megaMenu:hover .megaContent{
            display: flex;
            flex-wrap: wrap;
        }


        .megaMenu{
            position: relative;
            cursor: pointer;

            &:hover{
                background-color: #f3f3f3;

            }
            
            

            .megaContent{
                display: none;
                position: absolute;
                top: 100%;
                left: -90%;
                border-radius: 0 0 8px 8px;
                padding: 24px;
                background-color: rgb(225, 225, 225);

                li{
                    width: 160px;
                    margin-bottom: 12px;
                    white-space: nowrap;
                }
            }
            
        }

        li{
            font-size: 14px;
            padding: 12px;
            border-radius: 8px;
            list-style-type: none;
            cursor: pointer;

            &:hover{
                background-color: #f3f3f3;
            }

        }

        .active{
            background-color: white;
            font-weight:300;
            border-bottom: 2px solid grey;
            
        }

        .menus{
            
        }

    }


}