
$dominant-color-light : white;
$dominant-color-dark : #121239;
$complement-color-dark : #121239;
$complement-color-light : #e3e3f0;
$acent-color : linear-gradient(35deg,orange,red);
$acent-color-hover : linear-gradient(35deg,red,orange);
$box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
$title-size: 24px;
$title-weight:700;
$subtitle-size:16px;
$subtitle-weight:500;


// Breakpoints
$small-screen: 768px;
$medium-screen: 1024px;
$large-screen: 1440px;


// Typography
$body-font-family: 'Inter', sans-serif;
$heading-font-family: "Urbanist", Helvetica, Arial, sans-serif;

// Layout
$container-width: 90%;
$small-screen-container-width: 80%;
$medium-screen-container-width: 70%;
$large-screen-container-width: 60%;

//Font Size :
$font-size-small: 12px;
$font-size-medium: 16px;
$font-size-large: 29px;



//Color
$primary-color: #FFFFFF;    // Example primary color
$secondary-color: #311057;  // Example secondary color
$tertiary-color: #847895;   // Example tertiary color
$quaternary-color: #F1F0F8; // Example quaternary color
$quinary-color: #7C6E90;    // Example quinary color
$senary-color: #B8B4D2;     // Example senary color

// Spacing
$default-padding: 20px;


//Border Radius:
$default-border-radius: 8px;
$medium-border-radius:4px;
$small-border-radius:2px;
// Z-index
$z-index-modal: 1000;
$z-index-dropdown: 500;

// Button
$button-padding: 10px 20px;
$button-border-radius: 4px;
$button-font-size: 16px;
$button-font-weight: bold;

// Media Queries
@mixin respond-to($breakpoint) {
    @media only screen and (min-width: $breakpoint) {
        @content;
    }
}





//Height

$input-height : 40px;


//Icon Size:
$icon-medium-size : 18px;


$gradient: linear-gradient(30deg,orange,red);
$gradient-hover: linear-gradient(30deg,red,orange);
